@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,200;0,400;0,500;0,700;1,300;1,500&display=swap");

body {
  font-family: "Jost", sans-serif;
}

nav {
  z-index: 10001 !important;
}

.gradientBg {
  background: linear-gradient(99deg, #ee9ae5, #5961f9 100%);
}

.btnPrimary {
  @apply py-2 px-8 bg-secondary font-semibold text-white rounded hover:bg-pink transition-all duration-300;
}
